.amber-web-component__action_button {
    border-radius: 10rem;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.amber-web-component__action_button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10rem;
    z-index: -2;
}
.amber-web-component__action_button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #f40072;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
}
.amber-web-component__action_button:hover {
    color: #fff;
}
.amber-web-component__action_button:hover:before {
    width: 100%;
}
