div[data-amplify-authenticator] {
    --amplify-components-tabs-item-active-border-color: #eb0085;
    --amplify-components-tabs-item-active-color: #eb0085;
    --amplify-components-tabs-item-focus-color: #eb0085;
    --amplify-components-tabs-item-hover-color: #ff4400;
    --amplify-components-authenticator-state-inactive-background-color: 'transparent';
    --amplify-components-text-color: #000;
}

div[data-amplify-authenticator] [data-amplify-router] {
    box-shadow: unset;
    border-bottom-color: unset;
    border-width: unset;
    border-style: unset;
}

.amplify-passwordfield {
    --amplify-components-button-hover-color: #f7001b;
    --amplify-components-button-hover-background-color: #f7001b50;
    --amplify-components-button-hover-border-color: var(
        --amplify-components-textfield-border-color
    );
}

.amplify-button--link {
    display: flex;
    align-self: center;
    color: #f70035;
    width: fit-content;
}

.amplify-button--link:hover {
    color: #f70035;
    background-color: #e2e8f0;
}

.amplify-label.amplify-visually-hidden {
    width: unset;
    height: unset;
}

div[data-indicator-position='top'] > .amplify-tabs-item {
    border-width: 0 0 3px 0;
}

div.amplify-tabs[data-indicator-position='top'] {
    border-width: 0 0 0 0;
}

.amplify-button[data-variation='primary'] {
    background: linear-gradient(to right, #eb0085, #fc5500);
    border-radius: 2em;
    text-transform: uppercase;
}

.custom-checkbox .amplify-checkbox__icon {
    background-color: #f7001b;
}
