:root {
    --accent-color: #db1a4c;
    --corporat-black: #222;
    --light-background: #f4f4f4;
    --link-color: #06a6f5;
    --navigation-background: #fc5500;
}

/* fonts */
@font-face {
    font-family: 'poppinsregular';
    src: url('../assets/fonts/poppins-regular-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'poppinsbold';
    src: url('../assets/fonts/poppins-bold-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'robotolight';
    src: url('../assets/fonts/roboto-light-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'robotobold';
    src: url('../assets/fonts/roboto-bold-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
}

/* icons */
@font-face {
    font-family: 'icons';
    src: url('../assets/fonts/icons.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

/* general */
body {
    padding: 0;
    margin: 0;
    border: 0;
    font-family: 'robotolight';
    font-size: 1.35rem;
    line-height: 1.5;
    color: #222;
    background: #fefefe;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
}
b,
strong,
.bold {
    font-family: 'robotobold';
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'poppinsbold';
    font-weight: normal;
}
h1 {
    font-size: 1.75em;
}
h2 {
    font-size: 1.5em;
}
h3,
h4,
h5,
h6 {
    font-size: 1.25em;
}

.amber-web-component__map-wrapper {
    padding: 1em;
}

.amber-web-component__title {
    margin-top: 80px;
}

.amber-web-component__info {
    color: gray;
    font-style: italic;
}
@media (max-width: 1024px) {
    .center,
    .wide,
    .center_800,
    .center_900 {
        padding: 0 60px;
    }
}
@media (max-width: 600px) {
    .center,
    .wide,
    .center_800,
    .center_900 {
        padding: 0 30px;
    }
}

input::placeholder,
textarea::placeholder {
    color: var(--chakra-colors-gray-400);
}
