.legend-item-bullet {
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.5rem;
    vertical-align: center;
    text-align: left;
    background-color: var(--bullet-color, gray);
}

.leaflet-control-layers label {
    text-align: left;
}
