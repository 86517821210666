.slider .slick-prev:before,
.slider .slick-next:before {
    font-size: 50px;
    color: black;
    position: absolute;
    left: -15px;
}
.slider-box {
    padding: 5%;
}
.slider .slick-prev,
.slider .slick-next {
    position: absolute;
    z-index: 100;
}
